<template>
  <b-card id="canvas">
    <b-row>
      <b-col cols="6">
        <b-button @click="goBack">Back</b-button>
      </b-col>
      <b-col cols="6">
        <div class="float-right">
          <b-button @click="letterAirCon(idWar)" variant="primary"
            >Print</b-button
          >
        </div>
      </b-col>
    </b-row>
    <br />
    <b-modal
      v-model="loadingWarranty"
      centered
      no-close-on-backdrop
      hide-footer
      hide-header
    >
      <div
        class="d-flex flex-column align-items-center justify-content-center py-3"
      >
        <b-spinner medium variant="primary" label="Spinning"></b-spinner>
        <p class="mt-3">Fetch Warranty Registration Info</p>
      </div>
    </b-modal>

    <b-container class="px-0 mx-0">
      <b-row>
        <b-col
          style="display: flex; flex-direction: column; justify-items: center; align-items: center"
        >
          <img :src="daikinHeader" class="warranty-logo" />
          <!-- <h2 class="mb-2 font-weight-bold text-center">Warranty Registration</h2> -->
        </b-col>
      </b-row>
    </b-container>

    <b-container class="px-0 mx-0">
      <br />
      <br />
      <!-- <b-col>
          <b-row>
            
            <b-col cols="3"><span>Certificate Number</span></b-col>
            <b-col cols="3"><span style="font-weight:bold">{{warrantyData.cert}}</span></b-col>
            <b-col cols="6"></b-col>
            
          </b-row>
        </b-col> -->
      <div style="text-align:center;" class="registerType">
        <p style="font-weight:bold; font-size:22pt;">WARRANTY CERTIFICATE</p>

        <p
          style="font-weight:bold; font-size:22pt; text-align:center; margin-bottom:0mm"
        >
          {{ registerType }}
        </p>
      </div>
      <br />
      <br />
      <br />
      <b-row style="font-size:12pt">
        <b-col>
          <label>Certificate No.</label>&nbsp;:&emsp;
          <span style="font-weight:bold">{{ warrantyData.cert }}</span>
        </b-col>
      </b-row>
      <br />
      <b-row style="font-size:12pt"> </b-row>
      <br />
      <b-row style="font-size:12pt">
        <b-col><span style="font-weight:bold">Owner/Company Name</span></b-col>
      </b-row>
      <br />

      <b-row style="font-size:12pt">
        <b-col cols="6">
          <p
            style=" margin-bottom:0mm;"
            v-if="warrantyData.customerType === 'Company'"
          >
            {{ warrantyData.companyName }}
          </p>
          <p v-else style=" margin-bottom:0mm;">
            {{ warrantyData.salutation }}&nbsp;{{ warrantyData.fullName }}
          </p>
          <!-- <p style=" margin-bottom:0mm;">{{warrantyData.phone}}</p>
            <p style=" margin-bottom:0mm;">{{warrantyData.email}}</p> -->
          <p style=" margin-bottom:0mm;">
            {{ warrantyData.address }}&nbsp;{{ warrantyData.address2 }}
          </p>
          <!-- <p style=" margin-bottom:0mm;">{{warrantyData.address2}}</p> -->
          <p style=" margin-bottom:0mm;">
            {{ warrantyData.address3 }} &nbsp; {{ warrantyData.buildingName }}
          </p>
          <!-- <p style=" margin-bottom:0mm;">{{warrantyData.buildingName}}</p> -->
          <p style=" margin-bottom:0mm;">
            Singapore&nbsp;{{ warrantyData.postal }}
          </p>
        </b-col>
        <b-col cols="6">
          <b-row>
            <b-col
              style="display: flex; flex-direction: column; justify-items: center; align-items: center"
            >
              <img :src="daikinQR" style="width:25%" />
              <p>
                Visit the link above to learn how you can do smart and
                voice-control setup for your aircon!
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <br />

      <b-row style="font-size:12pt">
        <b-col cols="6">
          <div v-if="isAirCon(warrantyData.registerType)">
            <p style="font-weight:bold; margin-bottom:0mm;">Installer Name :</p>
            <p style=" margin-bottom:0mm;">{{ warrantyData.installerName }}</p>
          </div>
        </b-col>
        <b-col cols="6">
          <div v-if="isAirCon(warrantyData.registerType)">
            <span style="font-weight:bold">Installation Date</span>&nbsp;:&emsp;
            <span>{{ warrantyData.installationDate | formatDate }}</span>
          </div>
          <div v-else>
            <span style="font-weight:bold">Purchase Date</span>&nbsp;:&emsp;
            <span>{{ warrantyData.purchaseDate | formatDate }}</span>
          </div>
          <div>
            <span style="font-weight:bold">Promo Code</span>&nbsp;:&emsp;
            <span>{{ warrantyData.promoCode || "-" }}</span>
          </div>
        </b-col>
      </b-row>

      <!-- <b-row class="section-data">
          <h5>Installation Info</h5>
        

        <b-col cols="12" v-if="warrantyData.registerType === 'aircon'">
          <label>Installer Name</label>
          <b-form-input v-model="warrantyData.installerName" placeholder="Installer Name" disabled></b-form-input>
        </b-col>

        <b-col cols="12" class="hidden">
          <label>Installer Address</label>
          <b-form-input v-model="warrantyData.installerAddress" placeholder="Installer Address" disabled></b-form-input>
        </b-col>

        <b-col cols="12" v-if="warrantyData.registerType === 'aircon'">
          <label>Installation Date</label>
          <b-form-datepicker v-model="warrantyData.installationDate" disabled></b-form-datepicker>
        </b-col>
        </b-row> -->
      <br />
      <br />
      <!-- <b-row style="font-size:12pt">
        <b-col cols="12">
          
        </b-col>
        </b-row> -->

      <b-col cols="12" class="my-2 section-data" style="font-size:12pt">
        <h5>Product registered</h5>

        <b-table :items="warrantyData.items" :fields="fields" responsive>
          <template #cell(generalWarrantyPeriodEnd)="row">
            {{ row.item.generalWarrantyEndDate | formatDate }}
          </template>

          <template #cell(compressorWarrantyPeriodEnd)="row">
            {{
              row.item.compressorWarrantyEndDate
                ? row.item.compressorWarrantyEndDate
                : "-" | formatDate
            }}
          </template>

          <!-- <template #cell(actions)="row">
              <div class="d-flex">
                <b-button variant="danger" size="sm" :disabled="!(warrantyData.registerStatus !== 'Registered')" @click="confirmToDeleteItem(row)" class="mr-1">
                  <b-icon icon="trash"></b-icon>
                </b-button>

                <b-button variant="danger" size="sm" :disabled="!(warrantyData.registerStatus !== 'Registered')" @click="resetItemStatus(row.item._id)">
                  <b-icon icon="arrow-clockwise"></b-icon>
                </b-button>
              </div>
            </template> -->
        </b-table>
      </b-col>

      <b-row>
        <b-col>
          <span style="font-weight: bold;">PLEASE TAKE NOTE</span>
        </b-col>
      </b-row>
      <div v-if="registerType == 'Air Purifier'">
        <b-row>
          <ol>
            <li>
              This Warranty Certificate ("Certificate") must be presented to our
              service personnel each time warranty service is required.
            </li>
            <li>
              Please first check whether the power supply to the air-cleaner is
              properly connected before calling for repair service.
            </li>
            <li>
              In order to maintain the efficiency and prolong the life span of
              the air-cleaner, you are encouraged to routinely clean and change
              the filters.
            </li>
          </ol>
        </b-row>
      </div>
      <div v-if="registerType == 'Air Conditioner'">
        <b-row>
          <ol>
            <li>
              This Warranty Certificate ("Certificate") must be presented to our
              service personnel each time warranty service is required.
            </li>
            <li>
              Please first check whether the power supply to the air-conditioner
              is on and check on the remote controller battery before calling
              for service.
            </li>
            <li>
              If you are not sure how to operate the air-conditioner, please
              consult your installer.
            </li>
            <li>
              In order to maintain the efficiency and prolong the life span of
              the air-conditioner, you are encouraged to engage qualified
              air-conditioning contractors to carry out routine service.
            </li>
          </ol>
        </b-row>
      </div>

      <b-row>
        <b-col>
          <span
            >When requesting for service or repair, please furnish the following
            information:</span
          >
        </b-col>
      </b-row>
      <div>
        <b-row>
          <ol>
            <li>Full name and address</li>
            <li>Telephone number</li>
            <li v-if="registerType == 'Air Conditioner'">
              Symptom or the fault of the air-conditioner
            </li>
            <li v-if="registerType == 'Air Purifier'">
              Symptom or the fault of the air-cleaner
            </li>
          </ol>
        </b-row>
      </div>

      <b-row>
        <b-col>
          <span style="font-weight: bold;"
            ><u>Terms and Conditions of Warranty</u></span
          >
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col>
          <span style="font-weight: bold;"
            >The warranty period for the air-conditioner is as follows -</span
          >
        </b-col>
      </b-row>
      <b-row>
        <ol>
          <li v-for="(item, index) in form.primary" :key="index">
            <div>
              <span>
                {{ item }}
              </span>
            </div>
            <div v-for="(item, index2) in form.secondary[index]" :key="index2">
              <ul style="padding-inline-start:20px">
                <li>
                  <span>
                    {{ item }}
                  </span>
                </li>
              </ul>
            </div>
            <br />
          </li>
        </ol>
      </b-row>
      <br />
      <b-row>
        <span
          >THE WARRANTY PROVIDED IN THIS DAIKIN WARRANTY IS THE ONLY WARRANTY,
          WHETHER EXPRESS, IMPLIED OR STATUTORY, AND ALL OTHER WARRANTIES,
          WHETHER FOR MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE OR
          OTHERWISE IS HEREBY EXPRESSLY DISCLAIMED. In no event shall Daikin be
          liable for special or consequential damages arising from the purchase,
          possession or use of the air-conditioner sold by Daikin.</span
        >
      </b-row>
    </b-container>
    <br />
    <b-container class="px-0 mx-0">
      <b-row class="mb-2">
        <b-col
          cols="12"
          style="display: flex; flex-direction: column; justify-items: center; align-items: center"
        >
          <img :src="daikinFooter" class="warranty-footer" />
          <!-- <h2 class="mb-2 font-weight-bold text-center">Warranty Registration</h2> -->
        </b-col>
      </b-row>
    </b-container>
    <!-- <b-modal hide-footer v-model="deleteModal">
      <p>Please confirm you want to delete this item? </p>

      <b-button class="mt-2 mr-1" @click="doDeleteItem">Yes</b-button>
      <b-button class="mt-2" @click="deleteModal = false">No</b-button>
    </b-modal> -->
  </b-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "@/axios";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import pdfMake from "pdfmake/build/pdfmake";
import { qrcode, footer, header } from "../customer/daikin-img-base64";

export default {
  props: ["id"],
  data() {
    return {
      manualImage: require("@/assets/images/drcc/manual.png"),
      daikinHeader: require("@/assets/images/drcc/Daikin_Header.png"),
      daikinFooter: require("@/assets/images/drcc/Daikin_Footer.png"),
      daikinQR: require("@/assets/images/drcc/QR_Code_Daikin.png"),
      idWar: "",
      form: {
        primary: [],
        secondary: [],
        module: "",
        subModule: "",
        name: "",
      },
      fields: [
        { key: "itemModel", label: "Model" },
        { key: "serial", label: "Serial" },
        { key: "barcode", label: "Barcode" },
        // { key: 'sapDo', label: 'DO' },
        // { key: 'warrantyStatus', label: 'Warrany Status' },
        { key: "generalWarrantyPeriodEnd", label: "General Warranty End" },
        {
          key: "compressorWarrantyPeriodEnd",
          label: "Compressor Warranty End",
        },
      ],
      warrantyData: {},
      items: [],
      selectedItem: null,
      nonSaleItems: [],
      registerOptions: [
        { value: "aircon", text: "Air Conditioner" },
        { value: "airpur", text: "Air Purifier" },
      ],
      deleteModal: false,
      idTodelete: null,
      indexToDelete: 0,
      listSalutation: [
        { value: "MR", text: "Mr." },
        { value: "MS", text: "Ms." },
      ],
      model: "",
      serial: "",
      registerType: "",
      onSubmit: false,
      loadingWarranty: false,
    };
  },
  mounted() {
    axios
      .get(`/sales-and-purchase/drcc/warranty/detail/${this.$route.params.id}`)
      .then((res) => {
        this.warrantyData = res.data.data;
        if (this.isAirCon(this.warrantyData.registerType)) {
          this.registerType = "Air Conditioner";
        } else {
          this.registerType = "Air Purifier";
        }
        // console.log(this.warrantyData)
      })
      .catch((err) => console.log({ err }));

    setTimeout(() => {
      axios
        .get(
          `/sales-and-purchase/drcc/term-by/paperless warranty/${this.registerType}`
        )
        .then((res) => {
          console.log("res__data", res.data);
          this.form = res.data.data;
        })
        .catch((err) => console.log({ err }));
    }, 300);
  },
  computed: {
    terms() {
      let final = [];
      let primary = this.form.primary;
      let secondary = this.form.secondary;
      for (let i = 0; i < primary.length; i++) {
        let temp = [];
        temp.push(primary[i]);
        if (secondary[i].length !== 0) {
          temp.push({ ul: secondary[i] });
        }
        final.push(temp);
      }
      console.log({ final });
      return final;
    },
    isAirPurified() {
      let flag =
        (this.warrantyData.purchaseDate !== undefined) &
        (this.warrantyData.purchaseDate !== null);
      if (flag) {
        return true;
      }

      return false;
    },
    warrantyErrorMessage: {
      get() {
        let { errorCode, errorStatus } = this.warrantyData;

        if (errorCode !== undefined || errorStatus !== undefined) {
          return `${errorCode} - ${errorStatus}`;
        }

        return ``;
      },
      set(val) {},
    },
  },
  methods: {
    async letterAirCon() {
      let getData = {};
      getData = this.warrantyData;

      function dateTransformWithDate(date) {
        let dates = new Date(date);
        let options = {
          weekday: "long",
          day: "numeric",
          month: "long",
          year: "numeric",
          timeZone: "Asia/Singapore",
        };
        let formattedDate = dates
          .toLocaleDateString("en-SG", options)
          .replace(/,/g, ""); // Remove commas
        return formattedDate;
      }
      // let data = this.getDataProject
      console.log("pro", getData);
      let cert = `${getData.cert}`;

      // var pdfMake = require("pdfmake/build/pdfmake.js");
      if (pdfMake.vfs == undefined) {
        // var pdfFonts = require("./customVfs.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      let date = new Date().toISOString().substring(0, 10);

      function dateTransform(date) {
        let dates = new Date(date);
        let options = {
          day: "numeric",
          month: "long",
          year: "numeric",
          timeZone: "Asia/Singapore",
        };
        let formattedDate = dates
          .toLocaleDateString("en-SG", options)
          .replace(/,/g, ""); // Remove commas
        return formattedDate;
      }

      let installDate = dateTransform(getData.installationDate);
      let purchaseDate = getData.purchaseDate
        ? dateTransform(getData.purchaseDate)
        : "-";
      let installerName = getData.installerName ? getData.installerName : "-";
      let companyName = getData.companyName ? getData.companyName : "-";
      let promo = getData.promoCode ? getData.promoCode : "-";

      let ownerInfo = "";
      let contentAircon = null;
      let contentAirpur = null;
      if (getData.customerType == "Individual") {
        ownerInfo = getData.fullName;
        contentAirpur = [
          {
            text: `WARRANTY CERTIFICATE\n${this.registerType}`,
            style: "warcert",
          },
          { text: `\n` },
          { text: `\n` },
          { text: `Certificate No    : ${cert}`, style: "subheader" },
          { text: `\n` },
          { text: `\n` },
          { text: `Owner/Company Name`, bold: true },
          { text: `\n` },
          {
            alignment: "",
            columns: [
              {
                text: `${getData.salutation} ${ownerInfo}\n${getData.address} ${getData.address2}\n${getData.address3} ${getData.buildingName}\nSingapore ${getData.postal}`,
              },
              { qr: "http://bit.ly/3le9YkT", fit: "60", alignment: "center" },
            ],
          },
          {
            alignment: "justify",
            columns: [
              {},
              {
                text: `Visit the link above to learn how you can do smart and voice-control setup for your aircon!`,
              },
            ],
          },
          // {text: `\n`},
          // {alignment:'justify',
          //   columns:[
          //     {text:[
          //       {text:`Installer Name : \n`, bold:true},
          //       {text:`${installerName}`, },
          //     ]},
          //     {text:[{text:`Installation Date :`, bold:true},`${installDate}`]},
          // ]},
          { text: `\n` },
          {
            alignment: "justify",
            columns: [
              {
                text: [
                  { text: `Company Name : \n`, bold: true },
                  { text: `${companyName}` },
                ],
              },
              {
                text: [
                  { text: `Purchase Date :`, bold: true },
                  `${purchaseDate}`,
                ],
              },
            ],
          },
          {
            alignment: "justify",
            columns: [
              {},
              {
                text: [
                  { text: `Promo Code : `, bold: true },
                  { text: `${promo}` },
                ],
              },
            ],
          },

          { text: `\n` },
          { text: `Product Registered \n`, decoration: "underline" },
          {
            color: "#444",
            table: {
              widths: ["20%", "15%", "25%", "20%", "20%"],
              // keepWithHeaderRows: 1,
              body: [
                [
                  {
                    text: "Model",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                    bold: true,
                  },
                  {
                    text: "Serial",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                    bold: true,
                  },
                  {
                    text: "Barcode",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                    bold: true,
                  },
                  {
                    text: "Date of Warranty Expiry",
                    style: "tableHeader",
                    alignment: "center",
                    colSpan: 2,
                    bold: true,
                  },
                  {},
                ],
                [
                  {},
                  {},
                  {},
                  { text: "General", bold: true, alignment: "center" },
                  { text: "Compressor", bold: true, alignment: "center" },
                ],
              ],
            },
            layout: {
              fillColor: function(rowIndex, node, columnIndex) {
                return rowIndex % 2 === 0 ? "#CCCCCC" : null;
              },
            },
          },
          { text: `\n` },

          { text: `PLEASE TAKE NOTE \n`, style: "header" },
          { text: `\n` },
          {
            ol: [
              'This Warranty Certificate ("Certificate") must be presented to our service personnel each time warranty service is required.',
              "Please first check whether the power supply to the air-cleaner is properly connected before calling for repair service.",
              "In order to maintain the efficiency and prolong the life span of the air-cleaner, you are encouraged to routinely clean and change the filters.",
            ],
          },
          { text: `\n` },
          {
            text: `When requesting for service or repair, please furnish the following information:`,
          },
          { text: `\n` },
          {
            ol: [
              "Full name and address",
              "Telephone number",
              "Symptom or the fault of the air-cleaner",
            ],
          },
          { text: `\n` },
          { text: `\n` },
          {
            text: `Terms and Conditions of Warranty \n`,
            decoration: "underline",
            fontSize: 13,
            bold: true,
          },
          { text: `\n` },
          { text: `The warranty period for the air-cleaner is as follows –\n` },
          { text: `\n` },
          {
            ol: this.terms,
          },
          { text: "\n" },
          { text: "\n" },
          {
            text: [
              "THE WARRANTY PROVIDED IN THIS DAIKIN WARRANTY IS THE ONLY WARRANTY, WHETHER EXPRESS, IMPLIED OR STATUTORY, AND ALL OTHER WARRANTIES, WHETHER FOR MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE OR OTHERWISE IS HEREBY EXPRESSLY DISCLAIMED. In no event shall Daikin be liable for special or consequential damages arising from the purchase, possession or use of the air-cleaner sold by Daikin.",
            ],
          },
          { text: "\n" },
          { text: "\n" },
        ];
        contentAircon = [
          {
            text: `WARRANTY CERTIFICATE\n${this.registerType}`,
            style: "warcert",
          },
          { text: `\n` },
          { text: `\n` },
          { text: `Certificate No    : ${cert}`, style: "subheader" },
          { text: `\n` },
          { text: `\n` },
          { text: `Owner/Company Name`, bold: true },
          { text: `\n` },
          {
            alignment: "",
            columns: [
              {
                text: `${getData.salutation} ${ownerInfo}\n${getData.address} ${getData.address2}\n${getData.address3} ${getData.buildingName}\nSingapore ${getData.postal}`,
              },
              { qr: "http://bit.ly/3le9YkT", fit: "60", alignment: "center" },
            ],
          },
          {
            alignment: "justify",
            columns: [
              {},
              {
                text: `Visit the link above to learn how you can do smart and voice-control setup for your aircon!`,
              },
            ],
          },
          { text: `\n` },
          {
            alignment: "left",
            columns: [
              {
                text: [
                  { text: `Installer Name : \n`, bold: true },
                  { text: `${installerName}` },
                ],
              },
              {
                text: [
                  { text: `Installation Date :`, bold: true },
                  `${installDate}`,
                ],
              },
            ],
          },
          // {text: `\n`},
          // {alignment:'justify',
          //   columns:[
          //     {text:[
          //       {text:`Company Name : \n`, bold:true},
          //       {text:`${companyName}`},
          //     ]},
          //     {text:[{text:`Purchase Date :`, bold:true},`${purchaseDate}`]},
          // ]},
          {
            alignment: "justify",
            columns: [
              {},
              {
                text: [
                  { text: `Promo Code : `, bold: true },
                  { text: `${promo}` },
                ],
              },
            ],
          },

          { text: `\n` },
          { text: `Product Registered \n`, decoration: "underline" },

          {
            color: "#444",
            table: {
              widths: ["20%", "15%", "25%", "20%", "20%"],
              // keepWithHeaderRows: 1,
              body: [
                [
                  {
                    text: "Model",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                    bold: true,
                  },
                  {
                    text: "Serial",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                    bold: true,
                  },
                  {
                    text: "Barcode",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                    bold: true,
                  },
                  {
                    text: "Date of Warranty Expiry",
                    style: "tableHeader",
                    alignment: "center",
                    colSpan: 2,
                    bold: true,
                  },
                  {},
                ],
                [
                  {},
                  {},
                  {},
                  { text: "General", bold: true, alignment: "center" },
                  { text: "Compressor", bold: true, alignment: "center" },
                ],
              ],
            },
            layout: {
              fillColor: function(rowIndex, node, columnIndex) {
                return rowIndex % 2 === 0 ? "#CCCCCC" : null;
              },
            },
          },
          { text: `\n` },

          { text: `PLEASE TAKE NOTE \n`, style: "header" },
          { text: `\n` },
          {
            ol: [
              'This Warranty Certificate ("Certificate") must be presented to our service personnel each time warranty service is required.',
              "Please first check whether the power supply to the air-conditioner is on and check on the remote controller battery before calling for service.",
              "If you are not sure how to operate the air-conditioner, please consult your installer.",
              "In order to maintain the efficiency and prolong the life span of the air-conditioner, you are encouraged to engage qualified air-conditioning contractors to carry out routine service.",
            ],
          },
          { text: `\n` },
          {
            text: `When requesting for service or repair, please furnish the following information:`,
          },
          { text: `\n` },
          {
            ol: [
              "Full name and address",
              "Telephone number",
              "Symptom or the fault of the air-conditioner",
            ],
          },
          { text: `\n` },
          { text: `\n` },
          {
            text: `Terms and Conditions of Warranty \n`,
            decoration: "underline",
            fontSize: 13,
            bold: true,
          },
          { text: `\n` },
          {
            text: `The warranty period for the air-conditioner is as follows –\n`,
          },
          { text: `\n` },
          {
            ol: this.terms,
          },
          { text: "\n" },
          { text: "\n" },
          {
            text: [
              "THE WARRANTY PROVIDED IN THIS DAIKIN WARRANTY IS THE ONLY WARRANTY, WHETHER EXPRESS, IMPLIED OR STATUTORY, AND ALL OTHER WARRANTIES, WHETHER FOR MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE OR OTHERWISE IS HEREBY EXPRESSLY DISCLAIMED. In no event shall Daikin be liable for special or consequential damages arising from the purchase, possession or use of the air-conditioner sold by Daikin.",
            ],
          },
          { text: "\n" },
          { text: "\n" },
        ];
      } else {
        ownerInfo = getData.companyName;
        contentAirpur = [
          {
            text: `WARRANTY CERTIFICATE\n${this.registerType}`,
            style: "warcert",
          },
          { text: `\n` },
          { text: `\n` },
          { text: `Certificate No    : ${cert}`, style: "subheader" },
          { text: `\n` },
          { text: `\n` },
          { text: `Owner/Company Name`, bold: true },
          { text: `\n` },
          {
            alignment: "",
            columns: [
              {
                text: `${ownerInfo}\n${getData.address} ${getData.address2}\n${getData.address3} ${getData.buildingName}\nSingapore ${getData.postal}`,
              },
              { qr: "http://bit.ly/3le9YkT", fit: "60", alignment: "center" },
            ],
          },
          {
            alignment: "justify",
            columns: [
              {},
              {
                text: `Visit the link above to learn how you can do smart and voice-control setup for your aircon!`,
              },
            ],
          },
          // {text: `\n`},
          // {alignment:'justify',
          //   columns:[
          //     {text:[
          //       {text:`Installer Name : \n`, bold:true},
          //       {text:`${installerName}`, },
          //     ]},
          //     {text:[{text:`Installation Date :`, bold:true},`${installDate}`]},
          // ]},
          { text: `\n` },
          {
            alignment: "justify",
            columns: [
              {
                text: [
                  { text: `Company Name : \n`, bold: true },
                  { text: `${companyName}` },
                ],
              },
              {
                text: [
                  { text: `Purchase Date :`, bold: true },
                  `${purchaseDate}`,
                ],
              },
            ],
          },
          {
            alignment: "justify",
            columns: [
              {},
              {
                text: [
                  { text: `Promo Code : `, bold: true },
                  { text: `${promo}` },
                ],
              },
            ],
          },

          { text: `\n` },
          { text: `Product Registered \n`, decoration: "underline" },
          {
            color: "#444",
            table: {
              widths: ["20%", "15%", "25%", "20%", "20%"],
              // keepWithHeaderRows: 1,
              body: [
                [
                  {
                    text: "Model",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                    bold: true,
                  },
                  {
                    text: "Serial",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                    bold: true,
                  },
                  {
                    text: "Barcode",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                    bold: true,
                  },
                  {
                    text: "Date of Warranty Expiry",
                    style: "tableHeader",
                    alignment: "center",
                    colSpan: 2,
                    bold: true,
                  },
                  {},
                ],
                [
                  {},
                  {},
                  {},
                  { text: "General", bold: true, alignment: "center" },
                  { text: "Compressor", bold: true, alignment: "center" },
                ],
              ],
            },
            layout: {
              fillColor: function(rowIndex, node, columnIndex) {
                return rowIndex % 2 === 0 ? "#CCCCCC" : null;
              },
            },
          },
          { text: `\n` },

          { text: `PLEASE TAKE NOTE \n`, style: "header" },
          { text: `\n` },
          {
            ol: [
              'This Warranty Certificate ("Certificate") must be presented to our service personnel each time warranty service is required.',
              "Please first check whether the power supply to the air-cleaner is properly connected before calling for repair service.",
              "In order to maintain the efficiency and prolong the life span of the air-cleaner, you are encouraged to routinely clean and change the filters.",
            ],
          },
          { text: `\n` },
          {
            text: `When requesting for service or repair, please furnish the following information:`,
          },
          { text: `\n` },
          {
            ol: [
              "Full name and address",
              "Telephone number",
              "Symptom or the fault of the air-cleaner",
            ],
          },
          { text: `\n` },
          { text: `\n` },
          {
            text: `Terms and Conditions of Warranty \n`,
            decoration: "underline",
            fontSize: 13,
            bold: true,
          },
          { text: `\n` },
          { text: `The warranty period for the air-cleaner is as follows –\n` },
          { text: `\n` },
          {
            ol: this.terms,
          },
          { text: "\n" },
          { text: "\n" },
          {
            text: [
              "THE WARRANTY PROVIDED IN THIS DAIKIN WARRANTY IS THE ONLY WARRANTY, WHETHER EXPRESS, IMPLIED OR STATUTORY, AND ALL OTHER WARRANTIES, WHETHER FOR MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE OR OTHERWISE IS HEREBY EXPRESSLY DISCLAIMED. In no event shall Daikin be liable for special or consequential damages arising from the purchase, possession or use of the air-cleaner sold by Daikin.",
            ],
          },
          { text: "\n" },
          { text: "\n" },
        ];
        contentAircon = [
          {
            text: `WARRANTY CERTIFICATE\n${this.registerType}`,
            style: "warcert",
          },
          { text: `\n` },
          { text: `\n` },
          { text: `Certificate No    : ${cert}`, style: "subheader" },
          { text: `\n` },
          { text: `\n` },
          { text: `Owner/Company Name`, bold: true },
          { text: `\n` },
          {
            alignment: "",
            columns: [
              {
                text: `${ownerInfo}\n${getData.address} ${getData.address2}\n${getData.address3} ${getData.buildingName}\nSingapore ${getData.postal}`,
              },
              { qr: "http://bit.ly/3le9YkT", fit: "60", alignment: "center" },
            ],
          },
          {
            alignment: "justify",
            columns: [
              {},
              {
                text: `Visit the link above to learn how you can do smart and voice-control setup for your aircon!`,
              },
            ],
          },
          { text: `\n` },
          {
            alignment: "left",
            columns: [
              {
                text: [
                  { text: `Installer Name : \n`, bold: true },
                  { text: `${installerName}` },
                ],
              },
              {
                text: [
                  { text: `Installation Date :`, bold: true },
                  `${installDate}`,
                ],
              },
            ],
          },
          // {text: `\n`},
          // {alignment:'justify',
          //   columns:[
          //     {text:[
          //       {text:`Company Name : \n`, bold:true},
          //       {text:`${companyName}`},
          //     ]},
          //     {text:[{text:`Purchase Date :`, bold:true},`${purchaseDate}`]},
          // ]},
          {
            alignment: "justify",
            columns: [
              {},
              {
                text: [
                  { text: `Promo Code : `, bold: true },
                  { text: `${promo}` },
                ],
              },
            ],
          },

          { text: `\n` },
          { text: `Product Registered \n`, decoration: "underline" },

          {
            color: "#444",
            table: {
              widths: ["20%", "15%", "25%", "20%", "20%"],
              // keepWithHeaderRows: 1,
              body: [
                [
                  {
                    text: "Model",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                    bold: true,
                  },
                  {
                    text: "Serial",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                    bold: true,
                  },
                  {
                    text: "Barcode",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                    bold: true,
                  },
                  {
                    text: "Date of Warranty Expiry",
                    style: "tableHeader",
                    alignment: "center",
                    colSpan: 2,
                    bold: true,
                  },
                  {},
                ],
                [
                  {},
                  {},
                  {},
                  { text: "General", bold: true, alignment: "center" },
                  { text: "Compressor", bold: true, alignment: "center" },
                ],
              ],
            },
            layout: {
              fillColor: function(rowIndex, node, columnIndex) {
                return rowIndex % 2 === 0 ? "#CCCCCC" : null;
              },
            },
          },
          { text: `\n` },

          { text: `PLEASE TAKE NOTE \n`, style: "header" },
          { text: `\n` },
          {
            ol: [
              'This Warranty Certificate ("Certificate") must be presented to our service personnel each time warranty service is required.',
              "Please first check whether the power supply to the air-conditioner is on and check on the remote controller battery before calling for service.",
              "If you are not sure how to operate the air-conditioner, please consult your installer.",
              "In order to maintain the efficiency and prolong the life span of the air-conditioner, you are encouraged to engage qualified air-conditioning contractors to carry out routine service.",
            ],
          },
          { text: `\n` },
          {
            text: `When requesting for service or repair, please furnish the following information:`,
          },
          { text: `\n` },
          {
            ol: [
              "Full name and address",
              "Telephone number",
              "Symptom or the fault of the air-conditioner",
            ],
          },
          { text: `\n` },
          { text: `\n` },
          {
            text: `Terms and Conditions of Warranty \n`,
            decoration: "underline",
            fontSize: 13,
            bold: true,
          },
          { text: `\n` },
          {
            text: `The warranty period for the air-conditioner is as follows –\n`,
          },
          { text: `\n` },
          {
            ol: this.terms,
          },
          { text: "\n" },
          { text: "\n" },
          {
            text: [
              "THE WARRANTY PROVIDED IN THIS DAIKIN WARRANTY IS THE ONLY WARRANTY, WHETHER EXPRESS, IMPLIED OR STATUTORY, AND ALL OTHER WARRANTIES, WHETHER FOR MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE OR OTHERWISE IS HEREBY EXPRESSLY DISCLAIMED. In no event shall Daikin be liable for special or consequential damages arising from the purchase, possession or use of the air-conditioner sold by Daikin.",
            ],
          },
          { text: "\n" },
          { text: "\n" },
        ];
      }

      // optimal width header 460
      var docDefinitionAirPur = {
        pageMargins: [50, 70, 50, 70],
        header: function(currentPage, pageCount, pageSize) {
          return [
            {
              image: `${header}`,
              alignment: "center",
              width: 500,
              margin: [0, 20, 0, 0],
            },
          ];
        },
        footer: function(currentPage, pageCount) {
          return [
            {
              image: `${footer}`,
              width: 400,
              alignment: "center",
              margin: [0, 0, 0, 0],
            },
          ];
        },

        content: contentAirpur,
        styles: {
          warcert: {
            fontSize: 20,
            bold: true,
            alignment: "center",
            margin: [10, 0, 10, 0],
            // color:'aliceblue',
            // background:'#40a6fa'
            color: "#40a6fa",
          },
          header: {
            fontSize: 16,
            bold: true,
          },
        },
      };

      var docDefinitionAirCon = {
        pageMargins: [50, 70, 50, 70],
        header: function(currentPage, pageCount, pageSize) {
          return [
            {
              image: `${header}`,
              alignment: "center",
              width: 500,
              margin: [0, 20, 0, 0],
            },
          ];
        },
        footer: function(currentPage, pageCount) {
          return [
            {
              image: `${footer}`,
              width: 400,
              alignment: "center",
              margin: [0, 0, 0, 0],
            },
          ];
        },

        content: contentAircon,
        styles: {
          warcert: {
            fontSize: 20,
            bold: true,
            alignment: "center",
            margin: [10, 0, 10, 0],
            // color:'aliceblue',
            // background:'#40a6fa'
            color: "#40a6fa",
          },
          header: {
            fontSize: 16,
            bold: true,
          },
        },
      };

      // console.log({docDefinitionAirCon})
      // console.log({docDefinitionAirPur})

      for (let i = 0; i < getData.items.length; i++) {
        const el = getData.items[i];
        // console.log('ele',el);
        let otherPart = dateTransform(el.generalWarrantyEndDate);
        let compressor = el.compressorWarrantyEndDate
          ? dateTransform(el.compressorWarrantyEndDate)
          : "-";
        // console.log(otherPart);
        // console.log(compressor);

        docDefinitionAirCon.content[15].table.body.push([
          { text: `${el.itemModel}`, alignment: "center" },
          { text: `${el.serial}`, alignment: "center" },
          { text: `${el.barcode}`, alignment: "center" },
          { text: `${otherPart}`, alignment: "center" },
          { text: `${compressor}`, alignment: "center" },
        ]);
        docDefinitionAirPur.content[15].table.body.push([
          { text: `${el.itemModel}`, alignment: "center" },
          { text: `${el.serial}`, alignment: "center" },
          { text: `${el.barcode}`, alignment: "center" },
          { text: `${otherPart}`, alignment: "center" },
          { text: `${compressor}`, alignment: "center" },
        ]);
      }

      var win = window.open("", "_blank");
      if (this.registerType == "Air Conditioner") {
        pdfMake.createPdf(docDefinitionAirCon).print({}, win);
      } else {
        pdfMake.createPdf(docDefinitionAirPur).print({}, win);
      }
    },
    isAirCon(status) {
      if (status == undefined) {
        false;
      }
      return status.toLowerCase() == "aircon";
    },
    goBack() {
      this.$router.go(-1);
    },
    getWarrantyData() {
      axios
        .get(
          `sales-and-purchase/drcc/external-warranty/detail/${this.$route.params.id}`
        )
        .then((response) => {
          this.warrantyData = response.data.data;
          this.loadingWarranty = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllItems() {
      axios
        .get(`sales-and-purchase/drcc/postal/non-order-items`)
        .then((response) => {
          this.nonSaleItems = response.data.data.map((item) => {
            return Object.assign(
              {},
              { value: item, text: `${item.itemModel} - ${item.barcode}` }
            );
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addItem() {
      this.warrantyData.items.push(this.selectedItem);
    },
    deleteItem(row) {
      this.warrantyData.items.splice(row.index, 1);
    },
    confirmToDeleteItem(row) {
      this.deleteModal = true;
      this.idTodelete = row.item._id;
      this.indexToDelete = row.index;
    },
    deletePaperlessWarranty() {
      if (confirm("Please confirm you want to delete this draft warranty? ")) {
        this.deleteExternalWarranty(this.warrantyData._id).then((result) => {
          if (result) {
            this.$router.go(-1);
          }
        });
      }
    },
    doDeleteItem() {
      this.deleteWarrantyItemId({
        id: this.idTodelete,
        warrantyId: this.warrantyData._id,
      })
        .then(() => {
          this.warrantyData.items.splice(this.indexToDelete, 1);
          this.getWarrantyData();
          this.deleteModal = false;
        })
        .catch((e) => {
          this.deleteModal = false;
        });
    },
    completeRegister() {
      axios
        .post(
          `sales-and-purchase/drcc/submitted-warranty/complete`,
          this.warrantyData
        )
        .then((response) => {
          this.$bvToast.toast("Register Complete", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.getWarrantyData();
        })
        .catch((e) => {
          console.log(e);
          this.$bvToast.toast("Register Failed", {
            title: "Fail",
            variant: "danger",
            solid: true,
          });
        });
    },
    processWarranty() {
      axios
        .put(`sales-and-purchase/drcc/process/warranty`, this.warrantyData, {
          params: { type: "External" },
        })
        .then((response) => {
          this.$bvToast.toast(`Process Warranty Registration Success`, {
            title: "Success",
            variant: "success",
            solid: true,
          });
        })
        .catch((e) => {
          this.$bvToast.toast(`Process Warranty Registration Failed`, {
            title: "Fail",
            variant: "danger",
            solid: true,
          });
        });
    },
    manualAddItem() {
      axios
        .post(`sales-and-purchase/drcc/warranty/admin-verify`, {
          registerType: this.warrantyData.registerType,
          serial: this.serial,
          model: this.model,
        })
        .then((response) => {
          this.warrantyData.items.push(response.data.data);
          this.barcode = "";
        })
        .catch((e) => {
          this.$bvToast.toast(`${e.response.data.message}`, {
            title: "Fail",
            variant: "danger",
            solid: true,
          });
        });
    },
    resetItemStatus(id) {
      if (confirm("Are You Sure To Reset Equipment Warranty Status ?")) {
        axios
          .put("sales-and-purchase/drcc/reset-item-status", {
            itemId: id,
          })
          .then((response) => {
            // this.getWarrantyData();
            this.getAllItems();

            let items = JSON.parse(JSON.stringify(this.warrantyData.items));
            let itemIndex = items.findIndex((item) => item._id === id);
            if (itemIndex > -1) {
              let item = items[itemIndex];
              item.warrantyStatus = "Pre";
              items.splice(itemIndex, 1, item);
            }
            this.warrantyData = Object.assign({}, this.warrantyData, {
              items: items,
            });

            this.$bvToast.toast(`Reset Item Warranty Status Success`, {
              title: "Success",
              variant: "success",
              solid: true,
            });
          })
          .catch((e) => {
            console.log(e);
            this.$bvToast.toast(`Reset Item Warranty Status Failed`, {
              title: "Fail",
              variant: "danger",
              solid: true,
            });
          });
      }
    },
    ...mapActions({
      deleteWarrantyItemId: "warranty/deleteExternalWarrantyItem",
      deleteExternalWarranty: "warranty/deleteExternalWarranty",
      getUsersBy: "auth/getUsersBy",
      addNewUser: "auth/newUser",
      editWarranty: "warranty/updateWarranty",
    }),
  },
};
</script>

<style scoped>
.registerType {
  background-color: rgb(64, 155, 230);
  color: aliceblue;
  padding: 5px;
  margin-left: 25%;
  margin-right: 25%;
}
.section-data {
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: relative;
  margin: 20px 0 20px 0px;
  height: auto;
}

.section-data h5 {
  position: absolute;
  top: -10px;
  left: 6px;
  background: white;
  padding: 0 10px;
}

.warranty-logo {
  width: 100%;
  height: auto;
  margin: auto;
}

.warranty-footer {
  width: 100%;
  height: auto;
  margin: 0 auto 15px auto;
}

@media only screen and (max-width: 426px) {
  .form-line label:first-child {
    margin: 0 0 5px 0;
    width: 100%;
  }

  .form-line {
    flex-direction: column;
  }

  .warranty-logo {
    width: 100%;
    height: auto;
  }
  .warranty-footer {
    width: 320px;
    height: auto;
    margin: 0 auto auto auto;
  }
}
#canvas {
  width: 1060px;
}
</style>
